import React, {Component} from "react";
import {ADD, HOME, LIST} from "../../consts/pages";
import Login from "./Login";
import authService from "../../services/auth.service";
import fetchService from "../../services/fetch.service";
import {initialized} from "../../consts/languages";
import List from "./List";
import Creation from "./Creation";

export const INITIAL_STATE = {
    page: HOME,
    langLoaded: false
};

export default class Home extends Component {

    state = INITIAL_STATE;

    onNext = page => this.setState({page});

    onCancel = () => this.setState(this.setState({page: LIST}));

    refreshAuth = () => setInterval(() => {
        authService.refreshAccessToken()
    }, 60 * 60 * 1000);

    componentDidMount() {
        this.refreshAuth();
    }

    render() {
        return this.router();
    }

    logout = async () => {
        await authService.signOut()
        this.setState({page: HOME});
        fetchService.setToken(undefined);
    }

    router = () => {
        const {page, langLoaded} = this.state;

        if (langLoaded) {

            const email = authService.userEmail;

            switch (page) {
                case HOME:
                    return <Login onNext={(page) => this.setState({page})}/>;
                case LIST:
                    return <List onNext={(page) => this.setState({page})}
                                 logout={this.logout}
                                 email={email}/>;
                case ADD:
                    return <Creation onNext={(page) => this.setState({page})}
                                     onCancel={this.onCancel}
                                     logout={this.logout}
                                     email={email}/>;
                default:
                    return <Login/>;
            }
        } else {
            initialized().then(() => this.setState({langLoaded: true}));
            return <span/>;
        }
    }

}