import React from "react";
import Layout from "../layout/Layout";
import FormContainer from "../layout/FormContainer";
import i18next from "i18next";
import {ADD} from "../../consts/pages";
import {jobApi} from "../../api/JobApi";
import authService from "../../services/auth.service";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import withStyles from "@material-ui/core/es/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import PageLoader from "../layout/PageLoader";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700
    },
    pagination: {
        width: "100%",
        marginBottom: "50px",
        display: "flex",
        justifyContent: "flex-end",
        height: "50px",
        alignItems: "center"
    },
    fab: {
        margin: "5px"
    },
    page: {
        marginRight: 20
    }
});

const LIMIT = 50;

class List extends React.Component {

    interval = undefined;

    state = {
        offset: 0,
        jobs: undefined
    };

    loadJobs = (offset) => {
        jobApi.getJobs(authService.userEmail, LIMIT, offset).then(jobs => {
            if (jobs && jobs.length !== 0) {
                this.setState({jobs, offset});
            }
        });
    };

    componentWillMount() {
        this.loadJobs(0);
        this.interval = setInterval(() => {
            this.loadJobs(this.state.offset);
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    nextPage = () => {
        this.loadJobs(this.state.offset + LIMIT);
    };

    previousPage = () => {
        if (this.state.offset !== 0) {
            this.loadJobs(this.state.offset - LIMIT);
        }
    };

    render() {

        const {onNext, classes, logout, email} = this.props;
        const {jobs} = this.state;

        const pageNumber = (this.state.offset / LIMIT) + 1;

        return (
            <Layout headerTitle={i18next.t("appName")}
                    onNext={() => onNext(ADD)}
                    canGoNext={true}
                    email={email}
                    logout={logout}>

                <PageLoader visible={jobs === undefined}/>

                <FormContainer width="90%" title="Library list">

                    <div className="jobs-container">

                        {jobs &&

                        <Paper>

                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">Library name</TableCell>
                                        <TableCell align="left">Status</TableCell>
                                        <TableCell align="left">Link</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jobs.map(job => (
                                        <TableRow key={job.id}>
                                            <TableCell align="left">
                                                {new Date(parseInt(job.timestamp)).toLocaleString()}
                                            </TableCell>
                                            <TableCell align="left">{job.libraryName}</TableCell>
                                            <TableCell align="left">{job.status}</TableCell>
                                            <TableCell align="left">
                                                {job.status === "FINISHED" &&
                                                <a href={job.libraryLink} target="_blank"
                                                   rel="noopener noreferrer">View</a>}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>

                            <div className={classes.pagination}>

                                <span className={classes.page}>Page : {pageNumber}</span>

                                <Fab size={"small"} className={classes.fab}
                                     color="primary" aria-label="Back"
                                     onClick={this.previousPage}>
                                    <Icon>keyboard_arrow_left</Icon>
                                </Fab>
                                <Fab size={"small"} className={classes.fab}
                                     color="primary" aria-label="Forward"
                                     onClick={this.nextPage}>
                                    <Icon>keyboard_arrow_right</Icon>
                                </Fab>
                            </div>

                        </Paper>}

                        {!jobs &&
                        <div
                            className="error-msg">Your library list is empty, click on the Add button (bottom right) to
                            create one</div>
                        }

                    </div>

                </FormContainer>

            </Layout>
        );

    }
}

export default withStyles(styles)(List);