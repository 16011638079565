import React from "react";
import Fab from "@material-ui/core/Fab";
import Icon from "@material-ui/core/Icon";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    fab: {
        margin: theme.spacing.unit,
        width: "60px !important",
        height: "60px !important"
    }
});

const NavButton = ({classes, className, icon, label, onClick}) => (
    <Fab  color="primary" aria-label={label} className={[classes.fab, className].join(' ')} onClick={onClick}>
        <Icon>{icon}</Icon>
    </Fab>
);

export default withStyles(styles)(NavButton);