import fetchService from "../services/fetch.service";

class LibraryApi {

    getLibrariesName() {
        let url = "/library";

        return new Promise((resolve, reject) => {
            fetchService.get(url).then(response => {
                if(response.error){
                    reject(response.error)
                } else {
                    resolve(response);
                }
            });
        });
    };

    getStorageAdmins() {
        let url = "/storageAdmin";

        return new Promise((resolve, reject) => {
            fetchService.get(url).then(response => {
                if(response.error){
                    reject(response.error)
                } else {
                    resolve(response);
                }
            });
        });
    };

}

export const libraryApi = new LibraryApi();
