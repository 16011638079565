import fetchService from "../services/fetch.service";

class JobApi {

    createLibrary(sourceLibraryName, libraryName, storageAdmin) {
        console.log("storage admin: " + storageAdmin);

        let url = "/job?sourceLibraryName={sourceLibraryName}&libraryName={libraryName}&storageAdmin={storageAdmin}"
            .replace("{sourceLibraryName}", encodeURIComponent(sourceLibraryName))
            .replace("{libraryName}", encodeURIComponent(libraryName))
            .replace("{storageAdmin}", encodeURIComponent(storageAdmin));

        console.log("URL: " + url);

        return new Promise((resolve, reject) => {
            fetchService.post(url).then(response => {
                if (response.jobId) {
                    resolve(response.jobId);
                } else {
                    reject();
                }
            }).catch(() => reject());
        });
    };

    getJobs(emailRequester, limit, offset) {
        let url = "/job?emailRequester={emailRequester}&limit={limit}&offset={offset}"
            .replace("{emailRequester}", encodeURIComponent(emailRequester))
            .replace("{limit}", limit)
            .replace("{offset}", offset);

        return new Promise((resolve, reject) => {
            fetchService.get(url).then(response => {
                resolve(response.jobs);
            }).catch(() => reject());
        });
    };

}

export const jobApi = new JobApi();
